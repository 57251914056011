* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Julius+Sans+One");
@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

/* ----- mixins ----- */
@mixin flex($column, $wrap, $justify-content) {
  display: flex;
  flex-flow: $column $wrap;
  justify-content: $justify-content;
}

@mixin img($url) {
  background-image: $url;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

@mixin parallax($url) {
  background-image: $url;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

@mixin transition($duration) {
  -webkit-transition: $duration;
  transition: $duration;
}

@mixin gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,190554+100&0+1,0.48+63,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(25, 5, 84, 0.5) 70%,
    rgba(25, 5, 84, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(25, 5, 84, 0.5) 70%,
    rgba(25, 5, 84, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(25, 5, 84, 0.5) 70%,
    rgba(25, 5, 84, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#190554',GradientType=0 ); /* IE6-9 */
}

/* ----- bg colors ----- */

$nav-bg: #211f26;
$a1-bg: #f6f4ff;
$a2-bg: #211f26;
$a2-box-bg: #16131e;
$a2-box-bg-active: #fff;
$rooms-bg: #fff;
$room-box-bg: #0a0321;
$client-box-bg: #ffffff;
$client-bg: #211f26;
$newsletter-bg: #190554;
$footer-bg: #211f26;
$body-bg: white;

/* ----- buttons colors ----- */

$book-btn-bg: #0095cf;
$btn-bg: #e8a898;
$next-btn-bg: #0095cf;
$room-btn-bg: #0095cf;
$see-more-btn-border: #fff;
$arrow-bg: #0095cf;

/* ----- text colors ----- */

$nav-a-clr: #fff;
$book-text-clr: #fff;
$h1-clr: #fff;
$h2-clr: rgb(58, 55, 55);
$p-light-clr: rgb(255, 255, 255);
$p-a1-clr: rgb(58, 55, 55);
$p-dark-clr: #16131e;
$a-hover-bg: #1082b1;
$nav-a-hover: #e0968a;
$footer-text-clr: #fff;

/* ----- fonts ----- */

$text: "Playfair display";
$header: "Julius Sans One";
$header-2: "Pacifico";

/* ----- text styles ----- */

h1 {
  font-size: 7.5em;
  font-family: $header;
  color: $h1-clr;
}

h2 {
  font-size: 3.125em;
  font-family: $header;
  color: $h2-clr;
  text-transform: uppercase;
}

p:first-letter {
  font-size: 20px;
}

p {
  font-size: 16px;
  font-family: "Playfair display";
  font-weight: 500;
  text-align: center;
  margin-left: 65px;
  margin-right: 65px;
  line-height: 25px;

  @media screen and (max-width: 480px) and (min-width: 370px) {
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
  }
}

a {
  font: {
    size: 1.25em;
    family: $text;
    // weight: 600;
  }
  color: $nav-a-clr;
  text: {
    decoration: none;
    // transform: lowercase;
  }
}

a.contact {
  font-size: 15px;
  font-weight: 500;
  color: rgb(19, 17, 17);
  font-family: "Playfair display";
}

* {
  padding: 0;
  box-sizing: border-box;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: darkgrey;
  min-width: 160px;
  height: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: -15px;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  // background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  // background-color: #3e8e41;
}

/* ----- buttons style ----- */

.btn {
  background-color: transparent;
  font-size: 1.25em;
  font-family: "Playfair display", cursive;
  @include transition(0.3s);
  padding: 5px 10px;
  overflow: auto;

  &:hover {
    transform: scale(1.2);
  }

  &:focus {
    outline: none;
  }
}

.icon {
  margin-right: 20px;
  height: 58px;
  display: flex;
  align-items: center;
  background-color: #bca573;
  color: black;

  @media screen and (min-width: 481px) {
    display: none;
  }
}

/* ----- top navigation ----- */
.nav-container {
  justify-content: end;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 888;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  font-family: $text;
  background-color: white;
  .grey {
    background-color: #6e6868;
    opacity: 0.8;
  }

  .hotel {
    font-family: great vibes;
    margin: 2px;
    padding: 10px;
    font-size: 29px;
    color: black;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;

    img {
      display: flex;
      justify-content: center;
    }
  }
  .hotel-show {
    margin: 2px;
    padding: 10px;
    font-size: 18px;
    color: black;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
    font-family: "Great vibes", cursive;

    img {
      display: flex;
      justify-content: center;
    }
  }

  .fa::before {
    padding: 5px;
    height: 14px;
    color: white;
    position: relative;
    left: 0px;
    @media screen and (max-width: 480px) and (min-width: 370px) {
      display: none;
    }
  }

  span.test {
    color: white;
    display: inline-block;
    // padding: 0.6rem 0 0.3rem 0;
    margin: 2px;
    padding: 10px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.4px;
    background-color: none;
    @media screen and (max-width: 480px) and (min-width: 370px) {
      display: inline-block;
    }
  }

  nav {
    position: fixed;
    width: 100%;
    z-index: 10;
    .show-links {
      .nav-link {
        // color: black;
      }
    }
    .nav-links {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-link {
        font-family: $text;
        display: inline-block;
        padding: 0.6rem 0 0.3rem 0;
        margin: 0 2rem 0 2rem;
        // color: black;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 400;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: white;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;
        }
        &:hover:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }

    // Hamburger menu icon
    .nav-icon {
      display: none;
      width: 31px;
      height: 43px;
      position: relative;
      margin-right: 30px;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      margin-top: 15px;

      .text {
        margin-top: 40px;
      }
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        // background: black;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 12px;
        }
        &:nth-child(4) {
          top: 24px;
        }
      }
      &.open {
        span {
          &:nth-child(1) {
            top: 12px;
            width: 0%;
            left: 50%;
            background: black;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
            background: black;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            background: black;
          }
          &:nth-child(4) {
            background: black;
            top: 12px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }
  }
}

.bon {
  height: 130px;
  width: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99999;
}

.grey {
  background-color: black;
  // opacity: 0.8;
  .hotel .test {
    display: none;
  }
}

.white {
  background-color: white;
}

.white-font {
  color: white;
}

.black-font {
  color: black;
}

.black {
  background-color: black;
}

/* ----- booking box ----- */

.book-now {
  position: absolute;
  top: 50px;
  right: 5.5%;
  width: 21%;
  height: 45px;
  background-color: $book-btn-bg;
  border: none;
  text-align: center;
  padding: 10px 5% 40px 5%;
  font: {
    family: $header;
    size: 1.375em;
  }
  color: $p-light-clr;
  z-index: 1;
  outline: none;
}

.book-box {
  @include flex(column, nowrap, center);
  align-items: center;
  position: absolute;
  top: 0;
  right: 5.5%;
  width: 21%;
  height: 0;
  background-color: $book-btn-bg;
  overflow: hidden;
  @include transition(0.5s);

  label {
    font: {
      size: 1.25em;
      family: $text;
    }
    color: $p-light-clr;
    width: 66.6%;
    text-align: left;
    margin: 2px 0;
  }
}

.book-input {
  background-color: transparent;
  width: 66.6%;
  height: 40px;
  margin: 0 0 35px 0;
  outline: none;
  border-bottom: 2px solid rgba(225, 225, 225, 1);
  border-top: none;
  border-left: none;
  border-right: none;
  font: {
    size: 0.818em;
    family: $text;
  }
  color: $p-light-clr;
  @include transition(0.2s);

  &:focus {
    font-size: 1.25em;
    outline: none;
    border-bottom: 2px solid rgba(225, 225, 225, 0);
  }
}

.book-btn {
  margin: 15px 0 0 0;
  overflow: hidden;
}

/* ----- main container ----- */

.main-container {
  @include flex(column, nowrap, flex-start);
  width: 100%;

  .section {
    position: relative;
  }

  /* ----- header / intro ----- */

  @keyframes intro1 {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  }

  .intro {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 100vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/okolice2.jpg));
      // background-position: center bottom;
      animation: intro1 10s forwards infinite;
      opacity: 1.4;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    a span.hotel {
      font-family: $text;
      margin: 2px;
      padding: 10px;
      font-size: 29px;
      color: white;
      text-align: center;
      position: relative;
      justify-content: center;
      align-items: center;
      font-family: "Playfair display", cursive;
    }
  }

  //oferta

  .intro_static.oferta {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;
    background-image: url(../img/pokoj.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/pokoj.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn_oferta {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Playfair display", cursive;
      font-size: 40px;
      text-align: center;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  //oferta_apartamenty

  .intro_static.oferta_apartamenty {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/oferta_apartamenty.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  //slider niedziwezia
  .sliders {
    display: flex;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .slider {
    position: relative;
    max-width: 620px;
    width: 100%;
    overflow: hidden;
    margin: auto auto;
    @media (max-width: 600px) {
      margin-right: 0px !important;
    }
  }

  .slider:hover > .button-wrapper {
    width: 100%;
  }

  .item-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    transition: transform 0.4s ease;
  }

  .item-wrapper img {
    width: 100%;
    object-fit: cover;
    flex-basis: 100%;
  }

  .button-wrapper {
    position: absolute;
    width: calc(100% + 200px);
    left: 50%;
    top: 70%;
    transform: translateX(-50%) translateY(calc(-50% - 25px));
    transition: width 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    background-color: #fff;
    // width: 50px;
    // height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.25s ease;
  }

  .btn:hover {
    transform: scale(1.1);
  }

  .indicator-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
  }

  .indicator.current {
    background-color: #777;
  }

  .indicator:not(.current):hover {
    background-color: #ddd;
  }
  //

  //lesna
  .slider1 {
    position: relative;
    max-width: 620px;
    width: 100%;
    overflow: hidden;
    margin: auto auto;
  }

  .slider1:hover > .button-wrapper1 {
    width: 100%;
  }

  .item-wrapper1 {
    display: flex;
    flex-direction: row;
    height: 100%;
    transition: transform 0.4s ease;
  }

  .item-wrapper1 img {
    width: 100%;
    object-fit: cover;
    flex-basis: 100%;
  }

  .button-wrapper1 {
    position: absolute;
    width: calc(100% + 200px);
    left: 50%;
    top: 70%;
    transform: translateX(-50%) translateY(calc(-50% - 25px));
    transition: width 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    background-color: #fff;
    // width: 50px;
    // height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.25s ease;
  }

  .btn:hover {
    transform: scale(1.1);
  }

  .indicator-wrapper1 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .indicator1 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
  }

  .indicator1.current {
    background-color: #777;
  }

  .indicator1:not(.current):hover {
    background-color: #ddd;
  }
  //

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn_oferta {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Playfair display", cursive;
      font-size: 40px;
      text-align: center;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  //oferta specjalna main

  .intro_static.oferta_specjalna {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/14.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn_oferta {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Playfair display", cursive;
      font-size: 40px;
      text-align: center;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  //oferta - wyciag

  .stok {
    @media screen and (max-width: 480px) and (min-width: 370px) {
      width: 100% !important;
    }
  }

  .intro_static.stacja_narciarska {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/narty.jpg));
      background-position: center center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn_oferta {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Playfair display", cursive;
      font-size: 40px;
      text-align: center;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  /* ----- oferta specjalna----- */

  .intro_static.oferta_specjalna_wigilia {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/zima_oferta.jpg));
      background-position: center;
      // animation: intro1 10s forwards infinite;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .intro_static.oferta_specjalna_wielkanoc {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/wielkanoc.jpg));
      background-position: center;
      // animation: intro1 10s forwards infinite;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .intro_static.oferta_specjalna_romantycznyweekend {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/romantyczny.jpeg));
      background-position: center;
      // animation: intro1 10s forwards infinite;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  ul.list {
    padding-left: 25px;
    text-align: left;
    list-style-type: none;
    li {
      position: relative;
      padding-left: 25px;
    }
    li:before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #b4976d;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Playfair display", cursive;
      font-size: 40px;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  .intro_static.oferta_specjalna_sylwester {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/sylwester.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .intro_static.oferta_specjalna_ferie {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/ferie.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  ul.list {
    padding-left: 25px;
    text-align: left;
    list-style-type: none;
    li {
      position: relative;
      padding-left: 25px;
    }
    li:before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #b4976d;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Playfair display";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Great Vibes", cursive;
      font-size: 60px;
      font-weight: 500;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  .intro_static.oferta_specjalna_czerwiec {
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/majowka.jpg));
      background-position: center;
      // animation: intro1 10s forwards infinite;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  ul.list {
    padding-left: 25px;
    text-align: left;
    list-style-type: none;
    li {
      position: relative;
      padding-left: 25px;
    }
    li:before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #b4976d;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Work Sans";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Great Vibes", cursive;
      font-size: 60px;
      font-weight: 500;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  .intro_static.oferta_specjalna_lato {
    p {
      font-family: "Playfair Display";
    }
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/lato_wakacje.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .intro_static.oferta_specjalna_andrzejki {
    p {
      font-family: "Playfair Display";
    }
    @include flex(column, nowrap, center);
    align-items: center;
    position: relative;

    min-height: 77vh;
    width: 100%;
    background-color: $a1-bg;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include img(url(../img/andrzejki.jpg));
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  ul.list {
    padding-left: 25px;
    text-align: left;
    list-style-type: none;
    li {
      position: relative;
      padding-left: 25px;
      font-family: "Playfair Display";
    }
    li:before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #b4976d;
    }
  }

  .oferta.btn {
    margin: 10px;
    color: grey;
    font-weight: 500;
    font-family: "Work Sans";
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .title-img {
    width: 90%;
    z-index: 2;
    margin: 0;

    img {
      width: 100%;
      background-size: cover;
    }
  }

  .see-more-btn {
    background-color: transparent;
    // border: 1px solid $see-more-btn-border;
    margin: 40px 20px;
    padding: 15px 20px;
    z-index: 1;
    text-align: center;
    overflow: auto;

    a {
      margin: auto;
      color: $p-light-clr;
      padding: 0;
      font-family: "Great Vibes", cursive;
      font-size: 60px;
      font-weight: 500;
      &:hover {
        color: $p-light-clr;
      }
    }
  }

  /* ----- about 1 section ----- */

  .about-1,
  .about-rooms {
    // @include flex(row, wrap, space-around);
    background-color: white;
    padding-top: 20px;

    .col-al-center {
      width: 100%;
      font-size: 35px;
      text-align: center;
      p:nth-last-of-type(1) {
        // font-size: 20px;
        text-align: center;
        padding-top: 10px;
      }

      .slideshow-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.5);
        margin-top: 20px;

        .mySlides1 {
          img {
            margin: 0;
            height: 300px;
            width: 203px;
            box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.5);
            // background-size: cover;
            padding: 5px;
            // position: absolute;
          }
        }
      }
    }
  }

  /* ----- room section ------*/

  /* Override UGG site */
  // #main {width: 100%; padding:0;}
  // .content-asset p {margin:0 auto;}
  // .breadcrumb {display:none;}

  /* Helpers */
  /**************************/
  .margin-top-10 {
    padding-top: 10px;
  }
  .margin-bot-10 {
    padding-bottom: 10px;
  }

  .main_block {
    @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  /* Typography */
  /**************************/
  // #parallax-world-of-ugg h1 {font-family:'Oswald', sans-serif; font-size:24px; font-weight:400; text-transform: uppercase; color:black; padding:0; margin:0;}
  #parallax-world-of-ugg h2 {
    font-size: 70px;
    letter-spacing: 5px;
    padding: 10px;
    text-align: center;
    color: rgb(255, 253, 248);
    font-weight: 500;
    text-transform: uppercase;
    z-index: 10;
    opacity: 0.9;
  }
  // #parallax-world-of-ugg h3 {font-family:'Oswald', sans-serif; font-size:14px; line-height:0; font-weight:400; letter-spacing:8px; text-transform: uppercase; color:black;}
  #parallax-world-of-ugg p {
    font-family: "Playfair display";
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
  }
  #parallax-world-of-ugg p:first-letter {
    font-size: 20px;
  }
  // .first-character {font-weight:400; float: left; font-size: 84px; line-height: 64px; padding-top: 4px; padding-right: 8px; padding-left: 3px; font-family: 'Source Sans Pro', sans-serif;}
  #parallax-world-of-ugg p.oferta {
    margin: 10px;
    color: grey;
    font-weight: 500;
    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .sc {
    color: #3b8595;
  }
  .ny {
    color: #3d3c3a;
  }
  .atw {
    color: #c48660;
  }

  /* Section - Title */
  /**************************/
  #parallax-world-of-ugg .title {
    padding: 20px;
    margin: 0 auto;
    text-align: center;
  }
  #parallax-world-of-ugg .title h1 {
    font-size: 35px;
    letter-spacing: 8px;
  }

  /* Section - Block */
  /**************************/
  #parallax-world-of-ugg .block {
    background: white;
    // padding: 60px;
    width: 100%;
    margin: 0 auto;
  }
  #parallax-world-of-ugg .block-gray {
    background: #f2f2f2;
    padding: 60px;
  }
  #parallax-world-of-ugg .section-overlay-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: rgb(110, 104, 104);
    opacity: 0.7;
  }

  /* Section - Parallax */
  /**************************/
  #parallax-world-of-ugg .parallax-one {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url("../img/oferta_02.jpg");
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  #parallax-world-of-ugg .parallax-two {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url("../img/przytulnie.jpg");
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  #parallax-world-of-ugg .parallax-three {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url("../img/okolice2.jpg");
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  #parallax-world-of-ugg .parallax-four {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url("../img/gospodarze.jpg");
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  #parallax-world-of-ugg .parallax-five {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url("../img/goscie.jpg");
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  /* Extras */
  /**************************/
  #parallax-world-of-ugg .line-break {
    border-bottom: 1px solid black;
    width: 150px;
    margin: 0 auto;
  }

  /* Media Queries */
  /**************************/
  @media screen and (max-width: 1400px) and (min-width: 768px) {
    #parallax-world-of-ugg .block {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    #parallax-world-of-ugg .block {
      padding: 30px;
      width: 100%;
    }
    #parallax-world-of-ugg h2 {
      font-size: 30px;
    }
    #parallax-world-of-ugg .block {
      padding: 10px;
    }
    #parallax-world-of-ugg .parallax-one,
    #parallax-world-of-ugg .parallax-two,
    #parallax-world-of-ugg .parallax-three,
    #parallax-world-of-ugg .parallax-four,
    #parallax-world-of-ugg .parallax-five {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
  @media screen and (max-width: 481px) and (min-width: 370px) {
    #parallax-world-of-ugg p {
      font-size: 20px;
    }
    #parallax-world-of-ugg h2 {
      font-size: 25px;
      text-align: center;
      font-weight: 700;
      padding: 20px;
    }
    #parallax-world-of-ugg .margin-top-10 {
      font-size: 20px;
      text-align: center;
      margin: 10px;
    }
    #parallax-world-of-ugg section.block {
      padding: 30px;
      width: 100%;
    }
    #parallax-world-of-ugg section.block p {
      font-size: 20px;
      text-align: center;
    }
    #parallax-world-of-ugg section p {
      font-size: 15px;
      text-align: center;
    }
    #parallax-world-of-ugg .parallax-one {
      overflow: hidden;
      position: relative;
      width: 100%;
      background-image: url("../img/oferta_02.jpg");
      background-attachment: unset;
      background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    #parallax-world-of-ugg .parallax-two {
      overflow: hidden;
      position: relative;
      width: 100%;
      background-image: url("../img/przytulnie.jpg");
      background-attachment: unset;
      background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    #parallax-world-of-ugg .parallax-three {
      overflow: hidden;
      position: relative;
      width: 100%;
      background-image: url("../img/okolice2.jpg");
      background-attachment: unset;
      background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    #parallax-world-of-ugg .parallax-four {
      overflow: hidden;
      position: relative;
      width: 100%;
      background-image: url("../img/gospodarze.jpg");
      background-attachment: unset;
      background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    #parallax-world-of-ugg .parallax-five {
      overflow: hidden;
      position: relative;
      width: 100%;
      background-image: url("../img/goscie.jpg");
      background-attachment: unset;
      background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  /* ----- about 2 section ----- */

  @keyframes svgAnimationA2 {
    0% {
      stroke-dashoffset: -3200;
      fill: none;
    }
    75% {
      stroke-dashoffset: 0;
      fill: none;
    }
    85% {
      fill: rgba(225, 225, 225, 0.1);
    }
    90% {
      fill: rgba(225, 225, 225, 0.4);
    }
    95% {
      fill: rgba(225, 225, 225, 0.7);
    }
    100% {
      stroke-dashoffset: 0;
      fill: rgba(225, 225, 225, 1);
      stroke-width: 1;
    }
  }

  .about-2 {
    // @include flex(row, wrap, space-around);
    padding: 5px;
    // margin: 20px;
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    // width: 100%;

    .slideshow-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      // box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.5);
      margin-top: 20px;

      .mySlides1 {
        img {
          margin: 0;
          height: 300px;
          width: 203px;
          box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.5);
          // background-size: cover;
          padding: 5px;
          // position: absolute;
        }
      }
    }

    img {
      background-repeat: no-repeat;
      background-position: center center;
      box-shadow: inset 5000px 0 0 0 rgba(255, 255, 255, 0.5);
      height: 145px;

      @media screen and (max-width: 481px) and (min-width: 370px) {
        height: 145px;
      }
    }

    .slider {
      // width: 349px;
      // margin: 2em auto;
      text-align: center;
      // margin-right: 0px;
    }

    .slider-wrapper {
      width: 100%;
      height: 210px;
      position: relative;
      text-align: center;
      margin: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slide {
      float: left;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 1s linear;
      vertical-align: middle;
    }

    .slider-wrapper > .slide:first-child {
      opacity: 1;
    }

    .tiles-row {
      display: flex;
      width: 72%;

      @media only screen and (max-width: 1023px) {
        flex-wrap: wrap;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 99%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: #7c7979;
        a {
          text-decoration: none;
        }
      }

      .article-container .overlay {
        opacity: 0.8;
        .small-text {
          transform: translateY(0);
        }
      }

      .article-container {
        position: relative;
      }

      .text-image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .main-text {
          color: white;
          font-size: 1.6rem;
          text-align: center;
        }
        .small-text {
          color: white;
          font-size: 1rem;
          transform: translateY(10px);
          transition: all 1s ease-out;
          width: 100%;
          text-align: center;
        }
      }

      .small-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width: 1023px) {
          width: 100%;
        }

        .article-container {
          width: 50%;

          @media only screen and (max-width: 550px) {
            width: 100%;
          }
        }
        .small-image {
          height: 50vh;
          width: 100%;
          background-position: center;
        }
      }
    }

    .tiles-row1 {
      display: flex;
      // width: 72%;

      @media only screen and (max-width: 1023px) {
        flex-wrap: wrap;
      }

      .overlay1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100;
        right: 100;
        height: 100%;
        width: 50%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: #575454;
        a {
          text-decoration: none;
        }
      }

      .article-container1:hover .overlay1 {
        opacity: 0.8;
        .small-text1 {
          transform: translateY(0);
        }
      }

      .article-container1 {
        position: relative;
      }

      .text-image1 {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 10px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .main-text1 {
          color: rgb(233, 199, 4);
          font-size: 20px !important;
        }
        .small-text1 {
          color: rgb(233, 199, 4);
          font-size: 15px !important;
          transform: translateY(10px);
          transition: all 1s ease-out;
        }
      }

      .small-container1 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width: 1023px) {
          width: 100%;
        }

        .article-container1 {
          width: 50%;
          display: flex;
          justify-content: center;
          // align-items: center;

          @media only screen and (max-width: 550px) {
            width: 100%;
          }
        }
        .small-image1 {
          height: 42vh;
          width: 50%;
          @media screen and (max-width: 480px) and (min-width: 370px) {
            width: 60%;
          }
        }
      }
    }
  }

  /* ----- contact ----- */

  .block-contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    //  width: auto;

    .text {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // flex-direction: column;

      .header {
        font-weight: 500;
        text-align: center;
        font-family: "Great vibes", cursive;
        font-size: 30px;
        padding: 10px;
      }
    }

    hr {
      // width: 100%;
      text-align: center;
      margin: 10px;
    }
    .test {
      width: auto;
    }

    .weather {
      display: flex;
      justify-content: center;
      @media screen and (max-width: 480px) and (min-width: 370px) {
        flex-wrap: wrap;
      }
    }

    .form {
      display: flex;

      @media screen and (max-width: 670px) and (min-width: 370px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .center,
        .center1 {
          width: 100%;
        }
        form .center1 p {
          text-align: center;
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        margin: 10px;
        width: 50%;
        // text-align: center;

        .one,
        .two,
        .three,
        .four {
          display: flex;
          justify-content: center;
        }

        p {
          margin: 10px;
          font-size: 15px;
          font-weight: 500;
          color: rgb(19, 17, 17);
          font-family: "Playfair display";
          text-align: center;
        }
      }

      .center1 {
        display: flex;
        flex-direction: column;
        font-family: "Playfair display";
        font-weight: 500;
        color: rgb(19, 17, 17);

        p {
          text-align: left;
          font-family: "Playfair display";
          color: rgb(19, 17, 17);
          font-weight: 400;
          font-size: 17px;
        }

        .formField {
          width: 87%;
          font-family: "Playfair display";
          border-bottom: 1px solid #ccc;
          border-top: 1px #ccc;
          border-left: 1px #ccc;
          border-right: 1px #ccc;
          border-radius: 3px;
          margin: 3px 0 10px 0;
          padding: 10px 3px;
        }
        textarea.formField {
          border: 1px solid #ccc;
          width: 350px;
          height: 70px;
        }
        #sendBtn {
          cursor: pointer;
          background: #b4976d;
          color: white;
          // background: linear-gradient(rgb(168, 167, 167) 0, #eee 100%);
          border: 1px solid #ccc;
          line-height: 32px;
          font-size: 14px;
          padding: 0 25px;
          border-radius: 3px;
          &:hover {
            background-color: #4e4e4d;
            cursor: pointer;
          }
        }

        #send_form_status {
          border: 1px dashed #ccc;
          display: none;
          padding: 0 10px;
          margin: 10px 0;
        }
        .status_ok {
          color: #060;
        }
        .status_err {
          color: #f00;
        }
      }

      p {
        text-align: center;
      }
    }
  }

  /* ----- footer ----- */

  .footer {
    width: auto;
    height: 45px;
    background-color: #313335;
    padding: 10px;
    color: white;
    display: flex;
    // position: relative;

    justify-content: center;
    align-items: center;
    flex-direction: row;
    p {
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}
/* ----- media ----- */

@media screen and (max-width: 1200px) {
  .book-now {
    font-size: 1.125em;
  }

  .book-box {
    label {
      font: {
        size: 1.125em;
        family: $text;
      }
    }
  }

  .rooms-container {
    width: 60%;
  }

  .box-room {
    width: 500px;
  }
}

@media screen and (max-width: 1000px) {
  .book-now {
    display: none;
  }

  .book-box {
    display: none;
  }

  .top-nav {
    width: 100%;
  }

  .normal-nav {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .hamburger-menu {
    display: flex;
  }

  .ham-a {
    position: relative;
    padding: 10px 0;
    margin: 0;
    z-index: 777;
    border-top: 1px solid rgba(225, 225, 225, 0.5);
  }
}

@media screen and (max-width: 788px) {
  .title-img {
    width: 40%;
    z-index: 2;
    margin: 0;
  }

  h2 {
    font-size: 1.75em;
  }

  .room-hidden p {
    font-size: 1.125em;
    line-height: 25px;
    margin: 20px auto 15px auto;
  }

  .box-client {
    width: 70%;
  }

  .op-slide p {
    font-size: 1em;
  }

  .clients {
    max-height: 400px;
  }
}

@media screen and (max-width: 610px) {
  .about-1,
  .newsletter {
    // margin-top: 15px;
    @include flex(column, wrap, space-around);
  }

  .col-a1,
  .col-ns {
    text-align: center;
    width: 100%;
    margin: 10px auto;
  }

  .col-a1 {
    margin: 0 auto 10px auto;
  }

  .col-ns-r {
    margin-bottom: 40px;
  }

  .rooms-container {
    width: 90%;
    padding: 5px;
  }

  .box-client {
    width: 85%;
  }
}

@media screen and (max-width: 480px) and (min-width: 370px) {

  .cennik{
    width: 100%;
  }

  .main-container,
  .col-a1,
  .col-ns,
  .col-al-center {
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .btn {
    font-size: 1em;
  }

  .top-nav a {
    font-size: 0.78em;
  }

  .op-slide p {
    font-size: 1em;
    line-height: 1.6em;
  }

  .box-room {
    margin: 5px auto 0 auto;
  }

  .room-hidden {
    width: 85%;
    height: 75%;
  }

  .room-hidden p {
    font-size: 0.8em;
    line-height: 20px;
    margin: 5px auto 5px auto;
  }

  .block-contact {
    display: flex;
    flex-direction: column;

    p.margin-top-10 {
      margin: 5px;
      letter-spacing: 1px;
      text-align: center !important;
    }
  }

  // img {
  //   padding: 5px;
  // }

  .section.slideshow-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    .mySlides1 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 80%;
      img {
        padding: 50px;
      }
    }
  }
}

.logo-show {
  display: none;
}

@media (max-width: 768px) {
  span.nav-link {
    // display: none;
  }
  .logo-show {
    display: block;
    position: absolute;
    left: 0;
    // margin-top: 8px;
  }
  .nav-container {
    nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &.nav-collapse {
      }
      .nav-icon {
        display: block;
        z-index: 2;
      }
      .nav-links {
        display: none;
        color: grey;
      }
      .show-links {
        display: block;
        position: absolute;
        z-index: 1;
        padding-top: 8vh;
        width: 100%;
        top: -10px;
        height: 102vh;
        color: grey;

        .dropbtn {
          // background-color: #04aa6d;
          color: white !important;
          padding: 16px;
          // font-size: 16px;
          border: none;
        }

        .dropdown {
          position: relative;
          display: inline-block;
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #9e8118;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          right: -30px;
        }

        .dropdown-content a {
          color: white;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }

        .dropdown:hover .dropbtn {
          // background-color: #3e8e41;
        }
        background-color: #605a45;
        animation: smooth 0.5s ease-in-out;
        .nav-link {
          display: block;
          padding: 17px 0;
          color: white;
          &:hover:before {
            visibility: hidden;
          }
        }
        .hotel {
          font-family: $text;
          margin: 2px;
          padding: 10px;
          font-size: 29px;
          color: white !important;
          text-align: center;
          position: relative;
          justify-content: center;
          align-items: center;
          font-family: "Great Vibes", cursive;
        }
      }
    }
  }
  .slideshow-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    .mySlides1 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      // width: 60%;
      img {
        padding: 5px;
      }
    }
  }
}

@media screen and (max-width: 1400px) and (min-width: 768px) {
  .cennik {
    width: 40% !important;
  }
}
